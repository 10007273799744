<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="反馈类型" prop="feedbackType">
        <el-radio-group v-model="ruleForm.feedbackType">
          <el-radio style="margin-left: 40px" label="1" border
            >功能异常</el-radio
          >
          <el-radio label="2" border>产品建议</el-radio>
          <el-radio label="3" border>其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="补充说明" prop="supplementaryNotes">
        <el-input
          style="margin-left: 40px; width: 650px"
          type="textarea"
          placeholder="请提供详细使用场景描述，帮助我们理解～感谢配合！"
          v-model="ruleForm.supplementaryNotes"
          :autosize="{ minRows: 10, maxRows: 17 }"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="请提供相关问题的截图或照片（文件格式支持.png和.jpg）"
        prop="uploadImg"
        :rules="
          ruleForm.feedbackType == 1 ? rules.uploadImg : [{ required: false }]
        "
        ><br />
        <el-upload
          style="margin-left: 116px; margin-top: 20px"
          action=""
          ref="upload"
          accept=".png,.jpg"
          :file-list="fileList"
          :on-change="handleChange"
          :before-upload="beforeUpload"
          :auto-upload="false"
          list-type="picture-card"
          :limit="6"
          :on-exceed="handleExceed"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <div style="position: absolute; left: 330px">
        <div style="display: flex">
          <input
            v-loading.fullscreen.lock="fullscreenLoading"
            type="button"
            class="centerCzbtn"
            @click="submit('ruleForm')"
            value="提交"
          />
          <input
            type="button"
            class="centerBtn ml20 backbtn"
            @click="goBack"
            value="返回"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="submitDialogVisible"
      width="30%"
      center
      custom-class="submitDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div slot="title" style="margin-top: 22px">
        <i class="el-icon-success" style="color: #52c31c; font-size: 60px"></i>
        <div><span style="font-size: 18px">提交成功</span></div>
      </div>
      <div style="padding: 0 60px 0 60px">
        您的反馈意见已成功提交，
        请耐心等待管理员审核，我们会尽快通知您意见处理结果。
        感谢您的反馈，您的积极参与是我们不断进步的动力。
      </div>
      <span slot="footer" class="dialog-footer">
        <input
          type="button"
          class="centerCzbtn"
          @click="continuedFeedback"
          value="继续反馈"
        />
        <input
          type="button"
          class="centerBtn ml20 backbtn"
          @click="goBack"
          value="查看反馈"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _AddFeedback, _EditFeedback } from '@/api/userFeedback'
export default {
  name: 'feedback',
  data() {
    let validateUpload = (rule, value, callback) => {
      if (this.fileList.length == 0) {
        return callback(new Error('图片不能为空'))
      } else {
        return callback()
      }
    }
    return {
      feedbackType: '',
      supplementaryNotes: '',
      dialogImageUrl: '',
      dialogVisible: false,
      fileType: ['png', 'jpg'],
      fileList: [],
      fullscreenLoading: false,
      submitDialogVisible: false,
      ruleForm: {
        feedbackType: '',
        supplementaryNotes: '',
        uploadImg: []
      },
      rules: {
        feedbackType: [
          {
            required: true,
            message: '请选择反馈类型',
            trigger: 'change'
          }
        ],
        supplementaryNotes: [
          {
            required: true,
            message: '请输入补充说明',
            trigger: 'blur'
          }
        ],
        uploadImg: [
          {
            validator: validateUpload,
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleChange(file, fileList) {
      if (file.type != '' || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          this.fileList = fileList
        } else {
          this.$message.error('上传文件格式不正确!')
          this.fileList = fileList.slice(0, fileList.length - 1)
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传
    async uploadFeedback() {
      this.fullscreenLoading = true
      let FormDatas = new FormData()
      FormDatas.append('feedbackContent', this.ruleForm.supplementaryNotes)
      FormDatas.append('feedbackType', this.ruleForm.feedbackType)
      if (this.$route.params.rowData) {
        let uploadList = this.fileList.filter(ele => {
          return ele.raw
        })
        let imageIdsList = []
        this.fileList.filter(ele => {
          imageIdsList.push(ele.id)
        })
        uploadList.forEach(ele => {
          FormDatas.append('files', ele.raw)
        })
        FormDatas.append('id', this.$route.params.rowData.id)
        FormDatas.append('imageIds', imageIdsList.toString())
        let { success, data, message } = await _EditFeedback(FormDatas)
        if (success) {
          this.fullscreenLoading = false
          this.$message.success('修改成功！')
          this.$nextTick(() => {
            this.$router.back()
          })
        } else {
          this.fullscreenLoading = false
          this.$message.error(message)
        }
      } else {
        this.fileList.forEach(ele => {
          FormDatas.append('files', ele.raw)
        })
        let { success, data, message } = await _AddFeedback(FormDatas)
        if (success) {
          this.fullscreenLoading = false
          // this.$message.success('反馈成功！')
          this.submitDialogVisible = true
        } else {
          this.fullscreenLoading = false
          this.$message.error(message)
        }
      }
    },
    // 提交
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.uploadFeedback()
        } else {
          return false
        }
      })
    },
    continuedFeedback() {
      this.submitDialogVisible = false
      this.$refs['ruleForm'].resetFields()
      this.$refs.upload.clearFiles()
      this.fileList = []
    },
    beforeUpload(file) {
      if (file.type != '' || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true
        } else {
          this.$message.error('上传文件格式不正确!')
          return false
        }
      }
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      })
    },
    // 返回上级
    goBack() {
      this.$router.back()
    }
  },
  mounted() {
    if (this.$route.params.rowData) {
      this.ruleForm.feedbackType =
        this.$route.params.rowData.feedbackType.toString()
      this.ruleForm.supplementaryNotes =
        this.$route.params.rowData.feedbackContent
      this.fileList = this.$route.params.rowData.imageList
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio__input {
  display: none !important;
}
::v-deep .submitDialog {
  border-radius: 5px;
  height: 323px;
}
::v-deep .el-radio.is-bordered {
  background-color: #f3f3f3;
  border: none;
}
::v-deep {
  .el-radio-group {
    > .is-checked {
      background: #e1edf7;
      color: #1683d9;
      border-radius: unset;
    }
  }
}
::v-deep .el-textarea__inner {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border: 0 solid #dcdfe6;
}
::v-deep .el-textarea .el-input__count {
  background: none;
  bottom: 0;
}
</style>
